.skip-link {
  position: fixed;
  inset-block-start: var(--space-4);
  inset-inline-start: var(--space-4);
  padding-block: var(--space-2);
  padding-inline: var(--space-5);
  box-shadow: var(--shadow-xxl);
  border-radius: var(--rounded-full);
  background-color: var(--color-surface);
  color: var(--color-primary-text-contrast);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  transform: translateX(-10000px);
  z-index: 4;

  &:focus {
    transform: translateX(0);
  }
}
