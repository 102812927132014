
// DO NOT EDIT THIS FILE DIRECTLY.
// Update it by running "npm run themes:generate"

:root {
  --font-family-default: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-fluid-x2: clamp(0.79rem, calc(0.76rem + 0.17vw), 0.89rem);
  --font-size-fluid-x1: clamp(0.89rem, calc(0.85rem + 0.19vw), 1rem);
  --font-size-fluid-0: clamp(1rem, calc(0.96rem + 0.22vw), 1.13rem);
  --font-size-fluid-1: clamp(1.13rem, calc(1.08rem + 0.24vw), 1.27rem);
  --font-size-fluid-2: clamp(1.27rem, calc(1.21rem + 0.28vw), 1.42rem);
  --font-size-fluid-3: clamp(1.42rem, calc(1.36rem + 0.31vw), 1.6rem);
  --font-size-fluid-4: clamp(1.6rem, calc(1.53rem + 0.35vw), 1.8rem);
  --font-size-fluid-5: clamp(1.8rem, calc(1.72rem + 0.39vw), 2.03rem);
  --font-size-fluid-6: clamp(2.03rem, calc(1.94rem + 0.44vw), 2.28rem);
  --font-size-fluid-7: clamp(2.28rem, calc(2.18rem + 0.5vw), 2.57rem);
  --font-size-fluid-8: clamp(2.57rem, calc(2.45rem + 0.56vw), 2.89rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --shadow-xs: 0 1px 3px rgba(100, 100, 100, 0.09);
  --shadow-sm: 0 1px 5px rgba(100, 100, 100, 0.05);
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px rgba(100, 100, 100, 0.05), 0 0 40px rgba(100, 100, 100, 0.015);
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px rgba(100, 100, 100, 0.14);
  --shadow-xl: 0 4px 12px rgba(100, 100, 100, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --shadow-xxl: 0 24px 38px 3px rgba(100, 100, 100, 0.16), 0 9px 86px 8px rgba(100, 100, 100, 0.1), 0 11px 15px -7px rgba(100, 100, 100, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --color-backdrop: var(--light, rgba(0, 0, 0, 0.25)) var(--dark, rgba(0, 0, 0, 0.5));
  --color-background: var(--light, #fff) var(--dark, hsl(240, 5.0%, 9.8%));
  --color-surface: var(--light, #fff) var(--dark, hsl(240, 5.0%, 9.8%));
  --color-primary-bg-subtle: var(--light, hsl(240, 100%, 99.0%)) var(--dark, hsl(243, 30.4%, 13.5%));
  --color-primary-bg: var(--light, hsl(240, 99.9%, 97.7%)) var(--dark, hsl(242, 32.6%, 18.9%));
  --color-primary-bg-hover: var(--light, hsl(240, 93.5%, 95.9%)) var(--dark, hsl(241, 33.5%, 22.6%));
  --color-primary-bg-active: var(--light, hsl(240, 86.5%, 93.4%)) var(--dark, hsl(241, 34.2%, 26.1%));
  --color-primary-line-weak: var(--light, hsl(240, 86.5%, 93.4%)) var(--dark, hsl(241, 34.2%, 26.1%));
  --color-primary-line: var(--light, hsl(240, 80.2%, 89.8%)) var(--dark, hsl(240, 35.0%, 31.0%));
  --color-primary-border: var(--light, hsl(239, 75.3%, 84.6%)) var(--dark, hsl(240, 36.0%, 39.4%));
  --color-primary-border-hover: var(--light, hsl(238, 73.9%, 77.5%)) var(--dark, hsl(240, 45.2%, 54.9%));
  --color-primary-focus-ring: var(--light, hsl(238, 73.9%, 77.5%)) var(--dark, hsl(240, 45.2%, 54.9%));
  --color-primary-bg-solid: var(--light, hsl(240, 60.0%, 59.8%)) var(--dark, hsl(240, 60.0%, 59.8%));
  --color-primary-bg-solid-hover: var(--light, hsl(240, 55.7%, 56.7%)) var(--dark, hsl(241, 66.4%, 65.7%));
  --color-primary-text: var(--light, hsl(240, 50.0%, 52.0%)) var(--dark, hsl(242, 100%, 81.0%));
  --color-primary-text-contrast: var(--light, hsl(238, 43.0%, 26.8%)) var(--dark, hsl(242, 92.0%, 93.5%));
  --color-primary-placeholder: var(--light, hsl(240, 60.0%, 59.8%)) var(--dark, hsl(240, 60.0%, 59.8%));
  --color-neutral-bg-subtle: var(--light, hsl(240, 20.0%, 98.0%)) var(--dark, hsl(240, 6.9%, 11.4%));
  --color-neutral-bg: var(--light, hsl(239, 13.4%, 95.4%)) var(--dark, hsl(227, 6.7%, 16.4%));
  --color-neutral-bg-hover: var(--light, hsl(238, 11.8%, 92.9%)) var(--dark, hsl(222, 6.6%, 19.4%));
  --color-neutral-bg-active: var(--light, hsl(237, 11.1%, 90.5%)) var(--dark, hsl(218, 6.5%, 22.1%));
  --color-neutral-line-weak: var(--light, hsl(237, 11.1%, 90.5%)) var(--dark, hsl(218, 6.5%, 22.1%));
  --color-neutral-line: var(--light, hsl(236, 10.6%, 87.9%)) var(--dark, hsl(214, 6.4%, 25.1%));
  --color-neutral-border: var(--light, hsl(234, 10.4%, 84.4%)) var(--dark, hsl(209, 6.2%, 29.4%));
  --color-neutral-border-hover: var(--light, hsl(231, 10.2%, 75.1%)) var(--dark, hsl(202, 5.8%, 37.5%));
  --color-neutral-focus-ring: var(--light, hsl(231, 10.2%, 75.1%)) var(--dark, hsl(202, 5.8%, 37.5%));
  --color-neutral-bg-solid: var(--light, hsl(230, 6.0%, 57.0%)) var(--dark, hsl(220, 6.0%, 44.0%));
  --color-neutral-bg-solid-hover: var(--light, hsl(227, 5.2%, 51.8%)) var(--dark, hsl(218, 5.3%, 51.5%));
  --color-neutral-text: var(--light, hsl(220, 6.0%, 40.0%)) var(--dark, hsl(220, 7.0%, 70.0%));
  --color-neutral-text-contrast: var(--light, hsl(210, 12.0%, 12.5%)) var(--dark, hsl(220, 7.0%, 93.5%));
  --color-neutral-placeholder: var(--light, hsl(230, 6.0%, 57.0%)) var(--dark, hsl(220, 6.0%, 44.0%))
};

@import './app';
